import axios from 'axios'
import { PaymentRequestPayload } from 'publisher/hooks/payment/usePaymentSubmit'
import { PageId } from '../store/page/PageStateInterface'
import { PurchaseProcessId } from '../store/payment/PaymentStateInterface'

export interface CreateOrderResponse {
  orderId: string
  redirectUrl: string
  options: {
    paypalOrderId: string
    hash: string
  }
}

export interface CreateSubscriptionResponse {
  orderId: string
  redirectUrl: string
  options: {
    paypalSubscriptionId: string
    paypalPlanId: string
    hash: string
  }
}

export const buyMainOffer = (
  pageId: PageId,
  body: Record<string, PaymentRequestPayload>,
) => axios.post(`/api/purchase-service/buy-main-offer/${pageId}`, body)

export const continuePurchase = <T>(url: string) => axios.get<T>(url)

export interface CaptureOrderBody {
  paypalOrderId?: string
  paypalPaymentId?: string | null
  paypalPayerId?: string | null
  facilitatorAccessToken?: string
  paymentSource: string
  hash: string
}

export const captureOrder = (body: CaptureOrderBody) =>
  axios.post('/api/payment/integration/paypal-rest/order/capture', body)

interface ValidateSubscriptionBody {
  paypalSubscriptionId: string
  hash: string
}

export const validateSubscription = (body: ValidateSubscriptionBody) =>
  axios.post('/api/payment/integration/paypal-rest/subscription/validate', body)
