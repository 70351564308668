import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { optInFail } from 'publisher/actions/optInActions'
import {
  buyMainOffer,
  captureOrder,
  continuePurchase,
  CreateOrderResponse,
  CreateSubscriptionResponse,
  validateSubscription,
} from 'publisher/api/paypalRestApi'
import { pageSelectors } from 'publisher/store'
import usePage from '../usePage'
import usePaymentSubmit from './usePaymentSubmit'

interface PayPalApprovedData {
  billingToken?: string | null
  facilitatorAccessToken?: string
  orderID: string
  payerID?: string | null
  paymentID?: string | null
  subscriptionID?: string | null
  authCode?: string | null
}

export const usePaypalRestPaymentHandler = (
  entity: OldEntityInterface | PaymentButtonInterface,
) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const [orderHash, setOrderHash] = useState('')
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async () => {
    return await submit(async (body, onSuccess) => {
      try {
        const {
          data: { redirect },
        } = await buyMainOffer(pageId, {
          payment_form: body,
        })
        const { data } = await continuePurchase<
          CreateOrderResponse | CreateSubscriptionResponse
        >(redirect)
        setOrderHash(data.options.hash)
        // TODO: fix submit return type to avoid using any
        if ('paypalOrderId' in data.options) {
          return data.options.paypalOrderId as any
        }
        return data.options.paypalSubscriptionId
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        }
      } finally {
        onSuccess()
      }
    })
  }

  const handleCaptureOrder = async (body: PayPalApprovedData) => {
    const { data } = await captureOrder({
      paypalOrderId: body.orderID,
      paypalPaymentId: body.paymentID,
      facilitatorAccessToken: body.facilitatorAccessToken,
      paypalPayerId: body.payerID,
      paymentSource: 'paypal',
      hash: orderHash,
    })

    if (data.redirectLink) {
      window.location.assign(data.redirectLink)
    }
  }

  const handleValidateSubscription = async (body: PayPalApprovedData) => {
    if (body.subscriptionID) {
      const { data } = await validateSubscription({
        paypalSubscriptionId: body.subscriptionID,
        hash: orderHash,
      })

      if (data.redirectLink) {
        window.location.assign(data.redirectLink)
      }
    }
  }

  return {
    errors,
    isLoading,
    handleSubmit,
    handleCaptureOrder,
    handleValidateSubscription,
  }
}
