import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryVat } from 'common/constants/countryVatList'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import {
  paymentMethodsWithPersonalTaxNumber,
  PaymentMethodEnum,
} from 'common/enums/PaymentMethodEnum'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import useOptIn, { selectors as optInSelectors } from 'publisher/hooks/useOptIn'
import { useRecaptchaValidation } from 'publisher/hooks/useRecaptchaValidation'
import {
  usePayment,
  usePage,
  useManagement,
  managementSelectors,
} from 'publisher/store'
import { useOptIn as useTypedOptIn } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export type PaymentRequestPayload = {
  customer: {
    fields: Record<FieldSlugEnum, string> // add type after refactoring opt-in
    popupId: string | null // will be removed
    isDesktop: boolean // will be removed
  }
  paymentMethod: string
  offerPricePlan?: number
  orderBumpOffers: number[]
  coupon?: number
  product?: {
    id?: number
    variant?: string
    quantity?: number
    shippingCondition?: number | null
    shippingRule?: number | null
  }
  popupId: string | null
  isDesktop: boolean
  captcha?: string
}

type PaymentHandlerType = (
  body: PaymentRequestPayload,
  onSuccess: () => void,
) => Promise<void>

export default function usePaymentSubmit(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const fields = useOptIn(optInSelectors.getFields)
  const [errors, setErrors] = useState<string[]>([])
  const pricePlans = usePayment(paymentSelectors.getPricePlans)
  const checkedPricePlanId = usePayment(paymentSelectors.getCheckedPricePlanId)
  const coupon = usePayment(paymentSelectors.getCheckedCoupon)
  const checkedBumpIds = usePayment(paymentSelectors.getCheckedBumpIds)
  const paymentMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const isCustomerPersonal = usePayment(paymentSelectors.isCustomerPersonal)
  const popupId = usePage(p => pageSelectors.getAscendantPopupId(p, entity))
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const confirmedCheckboxesIds = useManagement(
    managementSelectors.getConfirmedCheckboxesIds,
  )
  const product = usePayment(paymentSelectors.getProduct)
  const productQuantity = usePayment(paymentSelectors.getProductQuantity)
  const activeProductVariant = usePayment(
    paymentSelectors.getProductActiveVariant,
  )
  const selectedShipping = usePayment(paymentSelectors.getSelectedShipping)
  const mandatoryCheckboxes = usePage(p =>
    pageSelectors.getVisibleMandatoryCheckboxes(p, isDesktop, popupId),
  )
  const customerCountryCode = useTypedOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.Country),
  )
  const isTaxFieldIsDisabled = getCountryVat(customerCountryCode) === null

  const { validateRecaptchas } = useRecaptchaValidation(entity)

  const handlePayment = async (
    paymentMethodHandler: PaymentHandlerType,
    onError?: (errors: string[]) => void,
  ) => {
    setErrors([])
    const arePricePlansEmpty = pricePlans.length === 0

    if (arePricePlansEmpty && !product?.id) {
      setErrors([
        ...new Set(errors.concat([t('validation.empty_price_plans')])),
      ])
      onError && onError(errors)
      return
    }

    if (!paymentMethod) {
      setErrors([
        ...new Set(errors.concat([t('validation.empty_payment_methods')])),
      ])
      onError && onError(errors)
      return
    }

    const checkboxesErrors = mandatoryCheckboxes.reduce((errors, checkbox) => {
      return confirmedCheckboxesIds.includes(checkbox.id)
        ? errors
        : [
            ...errors,
            'options' in checkbox
              ? checkbox.options.errorText
              : checkbox.errorText,
          ]
    }, [] as string[])

    if (checkboxesErrors.length > 0) {
      setErrors(checkboxesErrors)
      onError && onError(errors)
      return
    }

    const { recaptchaToken, error: recaptchaError } = validateRecaptchas()

    if (recaptchaError) {
      setErrors([t('core.errors.something_went_wrong')])
      onError && onError(errors)
      return
    }

    if (isCustomerPersonal) {
      delete fields[FieldSlugEnum.CompanyName]
      if (
        !paymentMethodsWithPersonalTaxNumber.includes(
          paymentMethod as PaymentMethodEnum,
        )
      ) {
        delete fields[FieldSlugEnum.TaxNumber]
      }
    }

    if (
      !paymentMethodsWithPersonalTaxNumber.includes(
        paymentMethod as PaymentMethodEnum,
      ) &&
      isTaxFieldIsDisabled
    ) {
      delete fields[FieldSlugEnum.TaxNumber]
    }
    // todo we need to know why can't validate company name on a client side
    // const emptyFieldErrors: Record<string, string[]> = validateEmptyOptInFields(
    //   visibleOptInFields,
    //   t,
    // )
    // if (Object.keys(emptyFieldErrors).length) {
    //   dispatch(optInFail({ fields: emptyFieldErrors }))
    //   return
    // }
    const calculatedFormProperties = product
      ? {
          product: {
            id: product?.id,
            variant: activeProductVariant?.id,
            quantity: productQuantity,
            shippingCondition: selectedShipping?.conditionId,
            shippingRule: selectedShipping?.ruleId,
          },
          orderBumpOffers: checkedBumpIds,
          ...(coupon ? { coupon: coupon.id } : {}),
        }
      : {
          offerPricePlan: checkedPricePlanId,
          orderBumpOffers: checkedBumpIds,
          ...(coupon ? { coupon: coupon.id } : {}),
        }
    setLoading(true)
    return paymentMethodHandler(
      {
        customer: {
          fields,
          popupId,
          isDesktop,
        },
        popupId,
        isDesktop,
        paymentMethod,
        ...calculatedFormProperties,
        captcha: recaptchaToken,
      },
      () => setLoading(false),
    )
  }

  return {
    errors,
    setErrors,
    isLoading,
    setLoading,
    submit: handlePayment,
    fields,
  }
}
