import {
  ICardPaymentBrickPayer,
  ICardPaymentFormData,
} from '@mercadopago/sdk-react/bricks/cardPayment/type'
import { useRollbar } from '@rollbar/react'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import CommonButton from 'common/components/entities/Button/Button'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { centsToEuro } from 'common/utils/priceCalculator'
import { buyUpsellOfferCard } from 'publisher/api/mercadoPagoApi'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { useSelectedOfferPricing } from 'publisher/pages/offer-page/hooks/useSelectedOfferPricing'
import { calculateOfferPricing } from 'publisher/pages/offer-page/utils/calculateOfferPricing'
import ThreeDsModal, {
  ThreeDsInfo,
} from 'publisher/paymentProcessors/mercadoPago/components/ThreeDsModal'
import { AsyncMercadoPagoCardPaymentModal } from 'publisher/paymentProcessors/mercadoPago/components/cardPayment/PaymentModal'
import { useOptIn, usePayment } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import { PageId } from 'publisher/store/page/PageStateInterface'
import { PurchaseProcessId } from 'publisher/store/payment/PaymentStateInterface'
import { setOfferPricing } from 'publisher/store/payment/paymentActions'
import {
  getActivePricePlan,
  getOfferId,
  getProduct,
  getProductActiveVariant,
  getProductQuantity,
} from 'publisher/store/payment/paymentSelectors'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellSubmitBodyInterface } from '../types/UpsellSubmitBodyInterface'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function MercadoPagoUpsellButton({ entity }: UpsellPaymentButtonPropsType) {
  const rollbar = useRollbar()
  const dispatch = useDispatch()
  const { t } = useTranslation(['publisher'])

  const abortControllerRef = useRef<AbortController>()
  const [MercadoPagoCardPaymentModal, setMercadoPagoCardPaymentModal] =
    useState<AsyncMercadoPagoCardPaymentModal | null>(null)
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isThreeDsModalOpen, setIsThreeDsModalOpen] = useState(false)
  const [threeDsInfo, setThreeDsInfo] = useState<ThreeDsInfo>({
    creq: '',
    external_resource_url: '',
  })
  const [redirect, setRedirect] = useState<string>()

  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )
  const customerCountry = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.Country),
  )

  const offerId = usePayment(getOfferId)
  const product = usePayment(getProduct)
  const activeProductVariant = usePayment(getProductActiveVariant)
  const productQuantity = usePayment(getProductQuantity)
  const pricePlan = usePayment(getActivePricePlan)

  const selectedPricing = useSelectedOfferPricing({
    selectedProduct: product,
    selectedPricePlan: pricePlan,
    activeProductVariant,
  })

  const paymentAmount = selectedPricing
    ? centsToEuro(selectedPricing.grossAmount)
    : null

  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)

  const loadMercadoPago = async () => {
    const { default: lazyComponent } = await import(
      /* webpackChunkName: "mercado-pago-card-payment" */ 'publisher/paymentProcessors/mercadoPago/components/cardPayment/PaymentModal'
    )
    setMercadoPagoCardPaymentModal(() => lazyComponent)
  }

  useEffect(() => {
    if (!offerId || (!product?.id && !pricePlan?.id)) return

    const fetchPriceData = async () => {
      try {
        abortControllerRef.current = new AbortController()

        const newOfferPricings = await calculateOfferPricing({
          product,
          pricePlans: pricePlan ? [pricePlan] : [],

          offerId,
          country: customerCountry,
          activeProductVariant,
          taxNumber,
          productQuantity,
          abortSignal: abortControllerRef.current.signal,
        })

        dispatch(setOfferPricing(newOfferPricings))
      } catch (error) {
        if (error instanceof BadRequest || error instanceof InternalError) {
          setErrors([t('core.error.title')])
        }
      }
    }

    if (abortControllerRef.current?.signal) {
      abortControllerRef.current.abort('new_request_sent')
    }

    fetchPriceData()
  }, [
    product?.id,
    productQuantity,
    activeProductVariant,
    customerCountry,
    offerId,
    taxNumber,
    pricePlan?.id,
  ])

  useEffect(() => {
    const fetchMercadoPagoModal = async () => {
      try {
        await loadMercadoPago()
      } catch (e) {
        rollbar.error('Load mercado pago modal', e as Error)
      }
    }

    fetchMercadoPagoModal()
  }, [])

  const showCardFormModal = () => {
    setIsModalOpened(true)
  }

  const onThreeDsClose = useCallback(() => {
    setIsThreeDsModalOpen(false)
  }, [])

  const processPayment = useCallback(
    async (cardFormData: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
      await submit(
        async (
          body: UpsellSubmitBodyInterface,
          pageId: PageId,
          purchaseProcessId: PurchaseProcessId,
        ) => {
          try {
            const { data } = await buyUpsellOfferCard(
              pageId,
              purchaseProcessId,
              {
                upsell_payment_form: {
                  token: cardFormData.token,
                  mercadoPagoPaymentMethod: cardFormData.payment_method_id,
                  installments: cardFormData.installments,
                  ...body,
                },
              },
            )

            if (data.threeDsInfo) {
              setThreeDsInfo(data.threeDsInfo)
              setRedirect(data.redirect)
              setIsThreeDsModalOpen(true)
              await closeModalAndUnmount()
              return
            }

            await closeModalAndUnmount()
            window.location.assign(data.redirect)
          } catch (error) {
            if (error instanceof BadRequest) {
              setErrors(error.response.data.errors.common)
            } else if (error instanceof NetworkError) {
              setErrors([t('core.errors.no_connection')])
            } else if (error instanceof InternalError) {
              setErrors([t('core.error.title')])
            } else {
              rollbar.error('mercado pago upsell failed', error as Error)
              setErrors([t('core.error.title')])
            }
          }
        },
      )
    },
    [], // dependencies makes mercado pago load again and it looks visually bad, todo: refactor useUpsellSubmit
  )

  const closeModalAndUnmount = async () => {
    setIsModalOpened(false)
    await window.cardPaymentBrickController.unmount()
  }

  return (
    <div>
      {isModalOpened && paymentAmount && MercadoPagoCardPaymentModal && (
        <MercadoPagoCardPaymentModal
          amount={paymentAmount}
          onClose={closeModalAndUnmount}
          processPayment={processPayment}
        />
      )}
      {isThreeDsModalOpen && (
        <ThreeDsModal
          creq={threeDsInfo.creq}
          external_resource_url={threeDsInfo.external_resource_url}
          redirect={redirect}
          onClose={onThreeDsClose}
        />
      )}
      <StyleWrapper
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
        flex
      >
        <CommonButton
          onClick={showCardFormModal}
          disabled={isLoading || !paymentAmount}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </div>
  )
}

export default memo(MercadoPagoUpsellButton)
