import {
  Stripe,
  StripeError,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { optInFail } from 'publisher/actions/optInActions'
import { buyMainOffer, confirmPaymentIntent } from 'publisher/api/stripe'
import FieldErrors from 'publisher/components/FieldErrors'
import { IntentTypeEnum } from 'publisher/enums/IntentTypeEnum'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import CreditCardRowUi from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/ui/CreditCardRowUi'
import { getPaymentButtons } from 'publisher/reducers/pageReducer'
import { pageSelectors, usePage, usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

const getStripeConfirmHandler = (stripe: Stripe, type: IntentTypeEnum) =>
  type === IntentTypeEnum.setup
    ? stripe.confirmCardSetup
    : stripe.confirmCardPayment

const ExpressCheckout = () => {
  const rollbar = useRollbar()
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const dispatch = useDispatch()
  const entity = usePage(getPaymentButtons)
  const { t } = useTranslation()
  const { errors, setErrors, submit } = usePaymentSubmit(entity[0])
  const stripe = useStripe()
  const elements = useElements()

  const options: StripeExpressCheckoutElementOptions = {
    paymentMethods: {
      applePay: 'always',
      googlePay: 'never',
      amazonPay: 'never',
      link: 'never',
      paypal: 'never',
    },
  }

  const handleLoadError = async ({ error }: { error: StripeError }) => {
    // You do not have the capabilities required for payment method `transfers`
    if (error.type === 'invalid_request_error') {
      setErrors(['core.errors.stripe_invalid_request_error'])
    } else if (error.type !== 'api_connection_error') {
      // report to rollbar anything else except when stripe servers are unavailable
      rollbar.error('apple pay load error', error)
    }
  }

  const handleConfirm = async (
    event: StripeExpressCheckoutElementConfirmEvent,
  ) => {
    submit(
      async (body, onSuccess) => {
        if (!stripe || !elements) {
          rollbar.error('Apple pay no stripe elements')
          event.paymentFailed()
          return
        }

        // initiates apple pay system popup
        const { error: submitError } = await elements.submit()

        if (submitError) {
          rollbar.error('Apple pay submit error', submitError)
          // closes apple pay system popup with failure
          event.paymentFailed()
          return
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          elements,
        })

        if (error || !paymentMethod) {
          rollbar.error('Apple pay create payment method error', error)
          event.paymentFailed()
          return
        }

        try {
          const { data: mainOfferData } = await buyMainOffer(
            pageId,
            purchaseProcessId,
            {
              payment_form: {
                ...body,
                paymentMethodId: paymentMethod.id,
              },
            },
          )

          if (mainOfferData.intentSecret) {
            const mainOfferStripeConfirmHandler = getStripeConfirmHandler(
              stripe,
              mainOfferData.intentType,
            )

            const confirmCardPaymentData = mainOfferData.paymentMethodId
              ? { payment_method: mainOfferData.paymentMethodId }
              : undefined

            const { error } = await mainOfferStripeConfirmHandler(
              mainOfferData.intentSecret,
              confirmCardPaymentData,
            )

            if (!error) {
              const { data: bumpData } = await confirmPaymentIntent(
                pageId,
                purchaseProcessId,
              )
              // bump case
              if (bumpData.intentSecret) {
                const bumpOfferStripeConfirmHandler = getStripeConfirmHandler(
                  stripe,
                  bumpData.intentType,
                )

                const { error } = await bumpOfferStripeConfirmHandler(
                  bumpData.intentSecret,
                  { payment_method: bumpData.paymentMethodId },
                )

                if (!error) {
                  const { data } = await confirmPaymentIntent(
                    pageId,
                    purchaseProcessId,
                  )
                  window.location.assign(data.redirect)
                  return
                }
              }

              // redirect to upSell or thank you page
              window.location.assign(mainOfferData.redirect)
            } else {
              setErrors([error.message as string])
            }
          } else {
            // redirect to upSell or thank you page
            window.location.href = mainOfferData.redirect
          }
        } catch (error) {
          if (error instanceof BadRequest) {
            setErrors(error.response.data.errors.common)
            dispatch(optInFail({ fields: error.response.data.errors.fields }))
          } else if (error instanceof NetworkError) {
            setErrors([t('core.errors.no_connection')])
          } else if (error instanceof InternalError) {
            setErrors([t('core.error.title')])
          } else {
            rollbar.captureEvent(body, 'debug')
            rollbar.error('Apple pay error', error as Error)
          }
        } finally {
          onSuccess()
        }
      },
      () => {
        event.paymentFailed()
      },
    )
  }

  return (
    <CreditCardRowUi flexDirectionColumn>
      <ExpressCheckoutElement
        className={'StripeElement__express'}
        onConfirm={handleConfirm}
        onLoadError={handleLoadError}
        options={options}
      />
      <FieldErrors errors={errors} align="center" />
    </CreditCardRowUi>
  )
}

export default ExpressCheckout
