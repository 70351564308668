import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { memo } from 'react'
import { CommonPaypalRestButton } from 'common/components/entities/paypal-rest-button'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePaypalRestPaymentHandler } from 'publisher/hooks/payment/use-paypal-rest-payment-handler'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'

export interface PaypalRestOrderButtonProps {
  entity: PaymentButtonInterface
  currency: string
}

const PaypalRestOrderButton = ({
  entity,
  currency,
}: PaypalRestOrderButtonProps) => {
  const { handleSubmit, errors, isLoading, handleCaptureOrder } =
    usePaypalRestPaymentHandler(entity)

  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.PAYPAL_CLIENT_ID}`,
        currency: currency.toLocaleUpperCase(),
      }}
    >
      <StyleWrapper
        flex
        flexColumn
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      >
        <CommonPaypalRestButton
          createOrder={async () => (await handleSubmit()) as unknown as string}
          onApprove={async data => {
            await handleCaptureOrder(data)
          }}
          borderRadius={
            typeof entity.border.radius === 'number' ? entity.border.radius : 0
          }
          backgroundColor={entity.paypal?.backgroundColor}
          disabled={isLoading || isPreview}
        />
        <FieldErrors errors={errors} align="center" />
      </StyleWrapper>
    </PayPalScriptProvider>
  )
}

export default memo(PaypalRestOrderButton)
