import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { optInFail } from 'publisher/actions/optInActions'
import { buyMainOffer } from 'publisher/api/dLocalApi'
import usePaymentSubmit from 'publisher/hooks/payment/usePaymentSubmit'
import { usePage, pageSelectors } from 'publisher/store'
import { usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useDLocalBoletoAndPixPaymentHandler(
  entity: OldEntityInterface | PaymentButtonInterface,
) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const pageId = usePage(pageSelectors.getPageId)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async (body, onSuccess) => {
      try {
        const { data } = await buyMainOffer(pageId, purchaseProcessId, {
          payment_form: body,
        })

        if (data.redirect) {
          window.location.assign(data.redirect)
          return
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        }
      } finally {
        onSuccess()
      }
    })
  }

  return { errors, isLoading, handleSubmit }
}
