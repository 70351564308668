import React from 'react'
import Button from 'common/components/entities/Button/Button'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import useDLocalBoletoAndPixPaymentHandler from 'publisher/hooks/payment/useDLocalBoletoAndPixPaymentHandler'

export interface DLocalBoletoAndPixPaymentButtonProps {
  entity: PaymentButtonInterface
}

const DLocalBoletoAndPixPaymentButton = ({
  entity,
}: DLocalBoletoAndPixPaymentButtonProps) => {
  const { errors, isLoading, handleSubmit } =
    useDLocalBoletoAndPixPaymentHandler(entity)

  return (
    <StyleWrapper
      flex
      flexColumn
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <Button
        onClick={handleSubmit}
        disabled={isLoading}
        loading={isLoading}
        loadingText={entity.loadingText}
        attrId={entity.htmlAttrId}
        text={entity.text}
        subText={entity.subText}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        textColor={entity.textColor}
        mobileTextColor={entity.mobileTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        textFontFamily={entity.textFontFamily}
        textFontStyle={entity.textFontStyle}
        textFontWeight={entity.textFontWeight}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        subTextColor={entity.subTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontStyle={entity.subTextFontStyle}
        subTextFontWeight={entity.subTextFontWeight}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
        background={entity.background ?? entity.backgroundColor}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
      />
      <FieldErrors errors={errors} align="center" />
    </StyleWrapper>
  )
}

export default DLocalBoletoAndPixPaymentButton
