import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useRollbar } from '@rollbar/react'
import { Elements } from '@stripe/react-stripe-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import StyleWrapper from 'common/components/StyleWrapper'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonButton from 'common/components/entities/Button/Button'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { confirmPaymentIntent } from 'publisher/api/stripe'
import FieldErrors from 'publisher/components/FieldErrors'
import { IntentTypeEnum } from 'publisher/enums/IntentTypeEnum'
import usePage, { selectors as pageSelectors } from 'publisher/hooks/usePage'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import * as api from '../../../api/stripe'
import useUpsellSubmit from '../hooks/useUpsellSubmit'
import { UpsellPaymentButtonPropsType } from './UpsellPaymentButton'

function getStripeConfirmHandler(stripe: Stripe, type: IntentTypeEnum) {
  return type === IntentTypeEnum.setup
    ? stripe.confirmCardSetup
    : stripe.confirmCardPayment
}

function StripeCreditCardUpsellButton({
  entity,
}: UpsellPaymentButtonPropsType) {
  const rollbar = useRollbar()
  const { t } = useTranslation()
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const stripeAccount = usePayment(paymentSelectors.getStripeAccountId)
  const pageId = usePage(pageSelectors.getPageId)
  const { errors, setErrors, isLoading, submit } = useUpsellSubmit(entity)

  useEffect(() => {
    async function loadStripeObject() {
      const stripe = await loadStripe(process.env.STRIPE_KEY as string, {
        stripeAccount,
      })
      setStripe(stripe)
    }
    loadStripeObject()
  }, [stripeAccount])

  const handleSubmit = async () => {
    await submit(async (body: any) => {
      try {
        const { data } = await api.buyUpsell(pageId, purchaseProcessId, {
          upsell_payment_form: body,
        })
        const { intentSecret, intentType, redirect } = data
        if (intentSecret && stripe) {
          const upsellStripeConfirmHandler = getStripeConfirmHandler(
            stripe,
            intentType,
          )
          const { error } = await upsellStripeConfirmHandler(intentSecret)
          if (!error) {
            const { data } = await confirmPaymentIntent(
              pageId,
              purchaseProcessId,
            )
            // redirect to upSell or thank you page
            if (data.redirect) {
              window.location.href = data.redirect
            }
          } else {
            setErrors([error.message as string])
          }
        } else {
          // redirect to upSell or thank you page
          window.location.href = redirect
        }
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        } else {
          rollbar.error(`Stripe CC upsell failed`, error as Error)
        }
      }
    })
  }

  return (
    <Elements stripe={stripe}>
      <StyleWrapper
        styles={{ ...entity.margin, ...wrapperStyles }}
        mobileStyles={entity.mobileMargin}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      >
        <CommonButton
          onClick={handleSubmit}
          disabled={isLoading}
          text={entity.text}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          subText={entity.subText}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          background={entity.background ?? entity.backgroundColor}
          mobileBackground={
            entity.mobileBackground ?? entity.mobileBackgroundColor
          }
          attrId={entity.htmlAttrId}
          width={entity.width}
          mobileWidth={entity.mobileWidth}
          textColor={entity.textColor}
          subTextColor={entity.subTextColor}
          mobileTextColor={entity.mobileTextColor}
          mobileSubTextColor={entity.mobileSubTextColor}
          textFontSize={entity.textFontSize}
          mobileTextFontSize={entity.mobileTextFontSize}
          subTextFontSize={entity.subTextFontSize}
          mobileSubTextFontSize={entity.mobileSubTextFontSize}
          textFontFamily={entity.textFontFamily}
          textFontWeight={entity.textFontWeight}
          textFontStyle={entity.textFontStyle}
          subTextFontFamily={entity.subTextFontFamily}
          subTextFontWeight={entity.subTextFontWeight}
          subTextFontStyle={entity.subTextFontStyle}
          mobileTextFontFamily={entity.mobileTextFontFamily}
          mobileTextFontWeight={entity.mobileTextFontWeight}
          mobileTextFontStyle={entity.mobileTextFontStyle}
          mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
          mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
          mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          iconClassNameBefore={entity.iconClassNameBefore}
          iconClassNameAfter={entity.iconClassNameAfter}
          hover={entity.hover}
          mobileHover={entity.mobileHover}
        />
      </StyleWrapper>
      <FieldErrors errors={errors} align="center" />
    </Elements>
  )
}

export default StripeCreditCardUpsellButton
