import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import React, { memo } from 'react'
import { CommonPaypalRestButton } from 'common/components/entities/paypal-rest-button'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { usePaypalRestPaymentHandler } from 'publisher/hooks/payment/use-paypal-rest-payment-handler'
import useManagement from 'publisher/hooks/useManagement'
import { selectors as managementSelectors } from 'publisher/reducers/managementReducer'

export interface PaypalRestSubscriptionButtonProps {
  entity: PaymentButtonInterface
  currency: string
}

const PaypalRestSubscriptionButton = ({
  entity,
  currency,
}: PaypalRestSubscriptionButtonProps) => {
  const { handleSubmit, handleValidateSubscription, errors, isLoading } =
    usePaypalRestPaymentHandler(entity)

  const isPreview = useManagement(managementSelectors.isPreviewMode)

  return (
    <PayPalScriptProvider
      options={{
        clientId: `${process.env.PAYPAL_CLIENT_ID}`,
        currency: currency.toLocaleUpperCase(),
        vault: true,
        intent: 'subscription',
      }}
    >
      <StyleWrapper
        flex
        flexColumn
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        appearance={entity.appearance}
      >
        <CommonPaypalRestButton
          createSubscription={async () =>
            (await handleSubmit()) as unknown as string
          }
          onApprove={async data => {
            await handleValidateSubscription(data)
          }}
          borderRadius={
            typeof entity.border.radius === 'number' ? entity.border.radius : 0
          }
          backgroundColor={entity.paypal?.backgroundColor}
          disabled={isPreview || isLoading}
        />
        <FieldErrors errors={errors} align="center" />
      </StyleWrapper>
    </PayPalScriptProvider>
  )
}

export default memo(PaypalRestSubscriptionButton)
