import {
  PayPalButtons,
  PayPalButtonsComponentProps,
} from '@paypal/react-paypal-js'
import React from 'react'

export type PaypalBackgroundColorType =
  | 'gold'
  | 'blue'
  | 'silver'
  | 'white'
  | 'black'

interface CommonPaypalButtonProps
  extends Omit<PayPalButtonsComponentProps, 'style' | 'message'> {
  backgroundColor?: PaypalBackgroundColorType
  height?: number
  borderRadius?: number
  disabled?: boolean
}

export const CommonPaypalRestButton = ({
  backgroundColor = 'blue',
  height,
  borderRadius,
  ...rest
}: CommonPaypalButtonProps) => {
  return (
    <PayPalButtons
      {...rest}
      style={{
        layout: 'horizontal',
        label: 'paypal',
        shape: 'rect',
        disableMaxWidth: true,
        color: backgroundColor,
        height,
        borderRadius,
      }}
      forceReRender={[backgroundColor, height, borderRadius]}
      message={{}}
    />
  )
}
