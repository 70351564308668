import React from 'react'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import { usePayment } from 'publisher/store'
import {
  getActivePricePlan,
  getOfferCurrency,
} from 'publisher/store/payment/paymentSelectors'
import PaypalRestOrderButton from './paypal-rest-order-button'
import PaypalRestSubscriptionButton from './paypal-rest-subscription-button'

export interface PaypalRestPaymentButtonProps {
  entity: PaymentButtonInterface
}

export const PaypalRestPaymentButton = ({
  entity,
}: PaypalRestPaymentButtonProps) => {
  const pricePlan = usePayment(getActivePricePlan)
  const offerCurrency = usePayment(getOfferCurrency)

  const isSubscriptionPricePlan =
    pricePlan && pricePlan.type !== PricePlanTypeEnum.OneShot

  if (isSubscriptionPricePlan) {
    return (
      <PaypalRestSubscriptionButton entity={entity} currency={offerCurrency} />
    )
  }

  return <PaypalRestOrderButton entity={entity} currency={offerCurrency} />
}
