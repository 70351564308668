import React, { useCallback, useState } from 'react'
import Button from 'common/components/entities/Button/Button'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import useMercadoPagoPaymentHandler from 'publisher/hooks/payment/useMercadoPagoPaymentHandler'
import ThreeDsModal, {
  ThreeDsInfo,
} from 'publisher/paymentProcessors/mercadoPago/components/ThreeDsModal'

export interface MercadoPagoCreditCardPaymentButtonProps {
  entity: PaymentButtonInterface
}

const MercadoPagoCreditCardPaymentButton = ({
  entity,
}: MercadoPagoCreditCardPaymentButtonProps) => {
  const { errors, isLoading, setErrors, handleSubmit } =
    useMercadoPagoPaymentHandler(entity)

  const [isThreeDsModalOpen, setIsThreeDsModalOpen] = useState(false)
  const [redirect, setRedirect] = useState<string>()
  const [orderBumpOffers, setOrderBumpOffers] = useState<number[]>([])
  const [threeDsInfo, setThreeDsInfo] = useState<ThreeDsInfo>({
    creq: '',
    external_resource_url: '',
  })

  const handleThreeDs = useCallback(
    (threeDsData: ThreeDsInfo, bumpOffers: number[], redirect?: string) => {
      setThreeDsInfo(threeDsData)
      setRedirect(redirect)
      setOrderBumpOffers(bumpOffers)
      setIsThreeDsModalOpen(true)
    },
    [],
  )

  const onThreeDsClose = useCallback(
    (errors?: string[]) => {
      setIsThreeDsModalOpen(false)
      if (errors && errors.length) {
        setErrors(errors)
      }
    },
    [setErrors],
  )

  const handleMercadoSubmit = useCallback(
    (e: React.SyntheticEvent) => handleSubmit(e, handleThreeDs),
    [handleSubmit, handleThreeDs],
  )

  return (
    <StyleWrapper
      flex
      flexColumn
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <Button
        onClick={handleMercadoSubmit}
        disabled={isLoading}
        loading={isLoading}
        loadingText={entity.loadingText}
        attrId={entity.htmlAttrId}
        text={entity.text}
        subText={entity.subText}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        textColor={entity.textColor}
        mobileTextColor={entity.mobileTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        textFontFamily={entity.textFontFamily}
        textFontStyle={entity.textFontStyle}
        textFontWeight={entity.textFontWeight}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        subTextColor={entity.subTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontStyle={entity.subTextFontStyle}
        subTextFontWeight={entity.subTextFontWeight}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
        background={entity.background ?? entity.backgroundColor}
        mobileBackground={
          entity.mobileBackground ?? entity.mobileBackgroundColor
        }
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
      />
      <FieldErrors errors={errors} align="center" />
      {isThreeDsModalOpen && (
        <ThreeDsModal
          creq={threeDsInfo.creq}
          external_resource_url={threeDsInfo.external_resource_url}
          redirect={redirect}
          orderBumpOffers={orderBumpOffers}
          onClose={onThreeDsClose}
        />
      )}
    </StyleWrapper>
  )
}

export default MercadoPagoCreditCardPaymentButton
